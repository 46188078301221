export default {
    MAX_ATTACHMENT_SIZE: 5000000,
    s3: {
      REGION: "us-east-1",
      BUCKET: "tibil-notes"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://alyrd4hum8.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_p8dPXch4S",
      APP_CLIENT_ID: "2r25quqkn2bpgpdjnd3vtebh3s",
      IDENTITY_POOL_ID: "us-east-1:e66af2f7-de9e-4cf3-a40f-b5979fd6d3c6"
    }
  };
